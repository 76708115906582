import React from 'react'
import './skills.css'


export default function Skills() {
  return (
   
      <div className='skills-container'>
        <div className='mini-container'>
          <p className='pStark'>STACKS</p>
          <div className='stark-border'></div>
          <div className='stack'>
            <img src="./react.svg" alt="react" />
            <img src="./html.svg" alt="html" />
            <img src="./javascript.svg" alt="javascript" />
            <img src="./css.svg" alt="css" />
            <img src="./node.svg" alt="node" />
            <img src="./boostrap.svg" alt="" />
            <img src="sass.svg" alt="sass" /> 
            <img src="./git.svg" alt="git" />
          </div>
        </div>
      </div>
     
  )
}
