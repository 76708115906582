import React, { Component } from 'react'
import './about.css';




export default function about() {
  
  return (
     <div>
         
      <div className="about--container">
          <img className='about' src="./about-me.svg" alt="profile" />
          <div className="right--side">
            <p>Nice to meet you,</p>
            <h3 className='h3'> I am Michael Emmanuel</h3>
            <p>Working full time in React, javascript and SCSS, leading the team for revamping DotCMS administration system.</p>
            <h3 className='h3'>Code, Design, Learn and Teach.</h3>
            <p>I implement user interface design and solve user problems with HTML, CSS AND Javascript. I have 2 years of making products that solve user problems and implementing responsive website.
            I implement user interface design and solve user problems with HTML, CSS AND Javascript. I have 2 years of making products that solve user problems and implementing responsive website.I implement user interface design and solve user problems with HTML, CSS AND Javascript. I have 2 years of making products that solve user problems and implementing responsive website.</p>
          </div>
      </div>

     
    </div>
  )
}
