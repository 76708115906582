import React from 'react'
import './footer.css'




export default function footer() {
  return (
    <footer>
      <div className='footer-container'>
        <div>
          <div>
            <i class="fa-brands fa-maxcdn foot"></i>
            <p className='mike'>michael</p>
          </div>
        </div>
        <div className='footer-contact'>
           <p className='mind'>DO you have a project in mind?</p>
           <p>Contact me!</p>
           <p>michaelemmanuelq@gmail.com</p>
        </div>
        <div>
        
            <i class="fa-brands fa-linkedin-in michael"></i>
            <i class="fa-brands fa-github michael"></i>
            <i class="fa-brands fa-twitter michael"></i>
            
        </div>
       
      </div> 
     
    </footer>
  )
}
