import React from 'react'
import './profile.css'


export default function Main() {
  return (
   
    <div className='profile-container'>
      <div className='mini-container'>
        <p>PROJECTS</p>
      
          <div className='card'>
            <div className='items'>
              <p className='title'>PearWheel</p>
              <p className='description'>B2B Market place</p>
              <  hr/>
              <p className='brief-description'>A B2B marketplace where retailers purchase products directly from manufacturers; offers an innovative service leveraging AI to connect fast moving consumer goods to thousands of retailers and help with distribution.</p>
                <img className='profile--image' src="./pearwheal.png" alt="note" />
                <p className='view-project'> VIEW PROJECT </p>
            </div>

            <div className='items'>
              <p className='title'>PearWheel</p>
              <p className='description'>B2B Market place</p>
              <  hr/>
              <p className='brief-description'>A B2B marketplace where retailers purchase products directly from manufacturers; offers an innovative service leveraging AI to connect fast moving consumer goods to thousands of retailers and help with distribution.</p>
                <img className='profile--image' src="./pearwheal.png" alt="note" />
                <p className='view-project'> VIEW PROJECT </p>
            </div>

            <div className='items'>
              <p className='title'>PearWheel</p>
              <p className='description'>B2B Market place</p>
              <  hr/>
              <p className='brief-description'>A B2B marketplace where retailers purchase products directly from manufacturers; offers an innovative service leveraging AI to connect fast moving consumer goods to thousands of retailers and help with distribution.</p>
                <img className='profile--image' src="./pearwheal.png" alt="note" />
                <p className='view-project'> VIEW PROJECT </p>
            </div>

            <div className='items'>
              <p className='title'>PearWheel</p>
              <p className='description'>B2B Market place</p>
              <  hr/>
              <p className='brief-description'>A B2B marketplace where retailers purchase products directly from manufacturers; offers an innovative service leveraging AI to connect fast moving consumer goods to thousands of retailers and help with distribution.</p>
                <img className='profile--image' src="./pearwheal.png" alt="note" />
                <p className='view-project'> VIEW PROJECT </p>
            </div>
          
          </div> 
        </div>                                                         
    </div>
  
  )
}

{/* <img className='profile--image' src="b2b.png" alt="b2b" />
<img className='profile--image' src="airbnb.jpg" alt="airbnb" />
<img className='profile--image' src="meme.jpg" alt="meme" />
<img className='profile--image' src="chrome.jpg" alt="chrome" />
<img className='profile--image' src="blackjack.png" alt="blackjack" /> */}