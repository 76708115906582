import React from 'react'
import '../App.css'

import {Link} from "react-router-dom"

export default function Nav() {
    const linkStyles = {
        color: 'white',
        textDecoration : "none",
    }

    
  return (
    <div className='menu-container'>
        <Link  style={linkStyles} to="/">
          <i class="fa-brands fa-maxcdn"></i>
        </Link>
     
        <ul className='nav-list'>
            <Link  style={linkStyles} to="/About">
              <li>ABOUT ME</li>
            </Link>

            <Link style={linkStyles} to="/Skills">
              <li>SKILLS</li>
            </Link>
            <Link style={linkStyles} to="/Portfolio">
              <li>PORTFOLIO</li>
            </Link>
            <Link style={linkStyles} to="/Contact">
              <li className='contact'>CONTACT ME</li>
            </Link>
        </ul>
  
      </div>
  )
}
