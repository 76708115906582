
import './App.css';

import Portfolio from './components/Portfolio';
import About from './components/About'
import Footer from './components/Footer'
import Skills from './components/Skills';
// import Home from './components/Home'
// import {Route, Routes} from "react-router-dom"
import Contact from './components/Contact';
import HeroPage from './components/HeroPage';

function App() {
  return (
    <div className="App">
      
          <HeroPage />
          <About />
          <Skills />
          <Portfolio />
          <Contact />
          <Footer />
         
    </div>
  );
  
}



export default App;
