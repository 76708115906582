import React from 'react'
import './contact.css'


export default function Contact() {


 
  return (
    
    <div className='conatact-container'>
        <h3 className='h3-contact'>CONTACT ME</h3>
        <div className='flex-contact'>
            <div>
               <img className='contact-img' src="./contact.svg" alt="contact me" />
            </div>
            <div>
            <form
                action="https://formspree.io/f/xrgdgjok"
                method="POST"
               
                >
                <label>Email </label>
                
                <input type="email" name="email" />
            
                <label>Message</label>
                
                <textarea type="textarea" name="message"></textarea>
                
                <button className='button' type="submit">Send</button>
            </form>
        </div>
       </div>
    
    </div>
  )
}
