import React from 'react'
import Navbar from './Navbar'


export default function Header() {
 
   
  
  return (
    <div className='nav-container'>
      <Navbar />
      <div className='hero-container'>
        <div className='introduction'>
          <h3 className='firstword'>Hi, I am </h3>
          <p className='name'>Michael Emmanuel</p> 
          <p className='developer'>Front-end Developer / React developer</p>
          <span>
            <i class="fa-brands fa-linkedin-in michael"></i>
            <i class="fa-brands fa-github michael"></i>
            <i class="fa-brands fa-twitter michael"></i>
          </span>
        </div> 
          <img className='profileImage' src="./mike.png" alt="profile" />
      </div>
    </div>
  )
}
